/* .landing-page {
} */

.landing-page .social-media-icon:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.landing-page .social-media-icon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.landing-page .footer-link:hover {
  color: #d32f2f !important; /* Match the error.main color */
  transition: color 0.2s ease;
} 
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-cover-image {
  @apply bg-[url('../assets/backgrounds/login2.jpg')] bg-center bg-no-repeat bg-cover min-h-screen;
}

.auth-page .social-media-icon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.auth-page .social-media-icon:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
